<template>
  <div class="User">
    <div class="actionBar">
      <a-space>
        <a-range-picker
          style="width: 280px"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
        >
        </a-range-picker>
        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 250px"
          allowClear
          @search="onSearch"
        />
        <a-button icon="download" type="primary" @click="downloadR" v-if="handLists.top.indexOf('export') != -1">
          导出结果</a-button
        >
      </a-space>
    </div>
    <a-modal
      title="修改报名时间"
      v-model="visible"
      @ok="handleOk"
      okText="确定"
      cancelText="取消"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="报名时间">
          <a-date-picker
            v-model="addFromData.create_time"
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            :locale="locale"
          />
        </a-form-item>
        <a-form-item label="报名时间">
          <a-input
            placeholder="请输入新的报名时间"
            v-model="addFromData.create_time"
          />
        </a-form-item>
        <a-form-item label="时间格式">例如：{{ currentTime }} </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) =>
          `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>
      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
        ></FontIcon>
        <a-divider type="vertical" />
        </span>
        
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"> </FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { signIndex, signdataSave, signDelete } from "@/apis/index";
import { signExportResult } from "@/apis/down";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { watch } from "fs";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align:'center'
  },
  {
    title: "姓名",
    dataIndex: "username",
    scopedSlots: { customRender: "username" },
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "城市",
    dataIndex: "address",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "性别",
    dataIndex: "sex",
    scopedSlots: { customRender: "sex" },
    customRender(text) {
      switch (text) {
        case 1:
          return "男";
        case 2:
          return "女";
        default:
          return "保密";
      }
    },
  },
  {
    title: "简介",
    dataIndex: "content",
    scopedSlots: { customRender: "content" },
  },
  {
    title: "报名时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender(text) {
      switch (text) {
        case 0:
          return "";
        default:
          return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      locale,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        start_time: "",
        end_time: "",
      },
      count: 0,
      time: [],
      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
     handList() {
      return this.$store.state.menuHandleList;
    },
    currentTime() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
    time(a) {
      if (a) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
    },
  },

  created() {
    this.getSignIndex();
  },
  methods: {
    confirm(rec) {
      signDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSignIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    downloadR() {
      signExportResult(this.listParams).then((res) => {
        if (res.status == 200) {
          let blob = new Blob([res.data], {
            type: `application/csv`, //word文档为msword,pdf文档为pdf
          });
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement("a");
          let fname = `报名列表.csv`; //下载文件的名字
          link.href = objectUrl;
          link.setAttribute("download", fname);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    editMenu(rec) {
      (this.addFromData = {
        id: rec.id,
        create_time: "",
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      signdataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.visible = false;
          this.getSignIndex();
          this.$message.success("报名时间修改成功");
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSignIndex();
    },
    getSignIndex() {
      signIndex(this.listParams).then((res) => {
   
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSignIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getSignIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.User {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
.proveimg {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
</style>
